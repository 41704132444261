<template>
  <b-modal id="articleModal" title="Artikel hinzufügen" ok-only ok-variant="danger" ok-title="Speichern" size="xl">
      <div class="row">
        <div class="col-md-12">
          <div class="input-group input-group-sm">
              <input type="text" class="form-control" placeholder="Suchen" @keyup.enter="getAllArticles" v-model="search" />
              <button type="submit" class="btn btn-default" @click.prevent="getAllArticles">
                  <i class="fas fa-search"></i>
              </button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <table class="table table-striped">
              <thead>
                      <th>Artikelnummer</th>
                      <th>Artikelname</th>
                      <th>Hersteller</th>
                      <th>Preis</th>
                      <th>Steuersatz</th>
                      <th style="width:15%; text-align:center;">Aktionen</th>
              </thead>
              <tbody>
                  <tr v-for="(article,index) in articles.data" :key="article.id">
                      <td>{{ article.ordernumber }}</td>
                      <td>{{ generateName(index) }}</td>
                      <td>{{ article.article.producer.name }}</td>

                      <td v-if="$parent.params.tax == true">{{ article.price | toCurrency}}</td>
                      <td v-if="$parent.params.tax == false">{{ article.price_netto | toCurrency}}</td>
                      <td>{{ article.tax }}</td>
                      <td style="text-align:center;">
                          <!-- <router-link class="mr-1 btn btn-success btn-sm" :to="{name: 'products.details', params: {id: props.row.product.id}}" v-if="$auth.check('products.show') && props.row.product != null"><i class="fas fa-box"></i></router-link> -->
                          <b-button size="sm" @click="addArticle(index)" variant="primary"><i class="fas fa-plus"></i>Hinzufügen</b-button>
                      </td>
                  </tr>
              </tbody>
          </table>
          <div class="clearfix align-middle card-footer">
              <pagination class="float-left" :data="articles" @pagination-change-page="getAllArticles" :limit="3"></pagination>
              
              <select class="float-right form-control-sm" v-model="params.per_page">
                  <option value="25">25 Artikel</option>
                  <option value="50">50 Artikel</option>
                  <option value="75">75 Artikel</option>
                  <option value="100">100 Artikel</option>
                  <option value="125">125 Artikel</option>
                  <option value="150">150 Artikel</option>
              </select>
              <p v-if="notEmptyObject(articles)" class="float-right mr-2">Anzeige Eintrag {{ articles.meta.from }} - {{ articles.meta.to }} von {{ articles.meta.total }}</p>
          </div>
        </div>
      </div>
    </b-modal>
</template>

<script>
export default {
    data(){
    return {
        taxes: [],
        producers: {},
        articles: {},
        params: {
                    per_page: 25,
                },
                search: '',
        }
    },

    watch: {
        params: {
            handler () {
                this.getAllArticles();
            },
            deep: true
        },
    },

    methods: {
        generateName(index)
        {
            var article = this.articles.data[index];
            var name = article.article.name;

            article.values.forEach(function (value) {
                name = name + ' - ' + value.name;
            });

            return name;
            
        },

        addArticle(index){
            var article = this.articles.data[index];

            //Prüfe ob der Artikel bereits im Warenkorb liegt

            const result = this.$parent.params.articles.findIndex(item => item.articleordernumber == article.ordernumber);

            if(result == -1)
            {
                var type = 'article';
                if(article.article.bundle == 1)
                {
                    type = 'bundle';
                }

                this.$parent.params.articles.push({
                    'articleordernumber': article.ordernumber,
                    'name': this.generateName(index),
                    'quantity': 1,
                    'price_orig': article.price,
                    'price_netto_orig': article.price_netto,
                    'price': article.price,
                    'price_netto': article.price_netto,
                    'tax_rate': article.article.tax.tax,
                    'article_id': article.article.id,
                    'article_detail_id': article.id,
                    'type': type,
                    'deposit': article.deposit,
                    'deposit_type': article.pledge.name,
                    'discountable': article.article.not_discountable == 1 ? false : true,
                    'orig_name': article.article.name,
                });
            }

            else
            {
                this.$parent.params.articles[result].quantity += 1;
            }

            this.search = '';
            this.$bvModal.hide("articleModal");
        },

        getAllArticles(page = 1) {
            this.$Progress.start();
            this.axios
                .get("/details", {
                    params: {
                        page,
                        search: this.search.length >= 4 ? this.search : '',
                        ...this.params
                    }
                })
                .then((response) => {
                    this.articles = response.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        getProducers() {
            this.$Progress.start();
            this.axios
                .get("/producers")
                .then((response) => {
                    this.producers = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        loadTaxes(){
            this.$Progress.start();
            this.axios
                .get("/taxes")
                .then((response) => {
                    this.taxes = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        }
    },

    mounted() {
    this.loadTaxes();
    this.getAllArticles();
    this.getProducers();
  }

}
</script>

<style>

</style>
<template>
  <b-container fluid>
      <div class="row">
            <div class="col-md-2">
                <div class="form-group">
                    <label for="company">Firma:</label>
                    <select class="form-control form-control-sm" v-model="params.company_id">
                        <option value="">-- Wähle Firma --</option>
                        <option v-for="company in companies" :key="company.id" :value="company.id">{{ company.name }}</option>
                    </select>
                </div>
            </div>

            <div class="col-md-1">
                <div class="form-group">
                    <label for="company">Kunde:</label><br>
                    <button class="btn btn-sm btn-primary" @click="selectCustomer" :disabled="params.company_id == '' || params.company_id == null">Kunde wählen</button>
                </div> 
            </div>
            <div class="col-md-1">
                <div class="form-group">
                    <label for="company">Brutto / Netto:</label><br>
                    <toggle-button
                        v-model="params.tax"
                        :labels="{checked: 'Brutto', unchecked: 'Netto'}"
                        :switch-color="{checked: 'green', unchecked: 'red'}" :width="100" :height="25" :font-size="14" :sync="true" :disabled="params.customer.inter_company == 1"
                    />
                </div>
            </div>
        </div>

        
        <div class="row">
            <div class="col-md-3" style="display: flex; flex-direction: column;">
                <div class="card" style="display: flex; flex-direction: column; flex: 1 0 auto;">
                    <div class="card-header">
                        <h3 class="card-title">Rechnungsadresse</h3>
                        <div class="card-tools">
                            <button v-if="notEmptyObject(params.customer)" class="btn btn-sm btn-secondary" @click="openAddressModal">Neue Adresse</button>
                        </div>
                    </div>

                    <div class="card-body">
                        <div class="form-group">
                            <label>Wähle</label>
                            <select class="form-control form-control-sm" v-model="params.billing_id">
                                <option v-for="address in params.customer.addresses" :key="address.id" :value="address.id">
                                    <template v-if="address.company != null && address.company != ''">{{ address.company }} / </template>
                                    <template v-if="address.department != null && address.department != ''">{{ address.department }} /</template>
                                    {{ address.first_name }} {{ address.last_name }} /
                                    {{ address.street }} {{ address.house_number }} /
                                    {{ address.zipcode }} {{ address.city }}
                                </option>
                            </select>
                        </div>
                    </div>

                </div>
            </div>

            <div class="col-md-3" style="display: flex; flex-direction: column;">
                <div class="card" style="display: flex; flex-direction: column; flex: 1 0 auto;">
                    <div class="card-header">
                        <h3 class="card-title">Rabatte</h3>
                    </div>
                    <div class="card-body">
                        <div class="form-group">
                            <label>Rabatt(%)</label>
                            <input type="number" min="0" oninput="this.value = Math.abs(this.value)" class="form-control form-control-sm" v-model="credit.discount" />
                        </div>

                        <div class="form-group" style="display: inline-block;">
                            <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                <input type="checkbox" class="custom-control-input" id="customSwitchDiscount" v-model="params.useDiscount">
                                <label class="custom-control-label" for="customSwitchDiscount">Rabatt anwenden?</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-3" style="display: flex; flex-direction: column;">
                <div class="card" style="display: flex; flex-direction: column; flex: 1 0 auto;">
                    <div class="card-header">
                        <h3 class="card-title">Kommentar</h3>
                    </div>
                    <div class="card-body">
                        <div class="form-group">
                            <label class="mt-2">Extern (Andruck auf der Gutschrift)</label>
                            <textarea class="form-control" rows="4" v-model="params.comment"></textarea>
                        </div>
                    </div>
                </div>
            </div>


            <div class="col-md-3" style="display: flex; flex-direction: column;">
                <div class="card" style="display: flex; flex-direction: column; flex: 1 0 auto;">
                    <div class="card-header">
                        <h3 class="card-title">Bestellinformationen</h3>
                    </div>
                    <div class="card-body">
                        <template v-if="params.tax == true">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <span>Zwischensumme (Positionen): {{ calculateSubTotalBrutto() | toCurrency }}</span>
                                        </div>
                                    </div>

                                    <div class="row" v-if="calculateDiscountBrutto() > 0">
                                        <div class="col-md-12">
                                            <span style="color: red">abzgl. Rabatt: {{ calculateDiscountBrutto() | toCurrency }}</span>
                                        </div>
                                    </div>

                                    <div class="row" v-if="calculateDepositReturnBrutto() > 0">
                                        <div class="col-md-12">
                                            <span>zzgl. Pfandrückgabe: {{ calculateDepositReturnBrutto() | toCurrency }}</span>
                                        </div>
                                    </div>

                                    <div class="mt-3 row">
                                        <div class="col-md-12">
                                            <hr>
                                            <span>Gesamtsumme (Netto): {{ calculateTotalNetto() | toCurrency }}</span>
                                        </div>
                                    </div>

                                    <div class="row" v-for="tax in calculateTaxesBrutto()" :key="tax.tax">
                                        <div class="col-md-12">
                                            <span><em>zzgl. {{ parseFloat(tax.tax) }} % MwSt: {{ tax.actualTax | toCurrency }}</em></span>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-12">
                                            <span><strong>Gesamtsumme (Brutto): {{ calculateTotalBrutto() | toCurrency }}</strong></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>

                        <template v-if="params.tax == false">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-md-12">
                                        <span>Zwischensumme (Positionen): {{ calculateSubTotalNetto() | toCurrency }}</span>
                                    </div>
                                </div>

                                <div class="row" v-if="calculateDiscountNetto() > 0">
                                    <div class="col-md-12">
                                        <span style="color: red">abzgl. Rabatt: {{ calculateDiscountNetto() | toCurrency }}</span>
                                    </div>
                                </div>

                                <div class="row" v-if="calculateDepositReturnNetto() > 0">
                                    <div class="col-md-12">
                                        <span>zzgl. Pfandrückgabe: {{ calculateDepositReturnNetto() | toCurrency }}</span>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <small>Alle Werte in Netto außer anders angegeben</small>
                                    </div>
                                </div>

                                <div class="mt-3 row">
                                    <div class="col-md-12">
                                        <hr>
                                        <span>Gesamtsumme (Netto): {{ calculateTotalNetto() | toCurrency }}</span>
                                    </div>
                                </div>

                                <div class="row" v-for="tax in calculateTaxesNetto()" :key="tax.tax">
                                    <div class="col-md-12">
                                        <span><em>zzgl. {{ parseFloat(tax.tax) }} % MwSt: {{ tax.actualTax | toCurrency }}</em></span>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <span><strong>Gesamtsumme (Brutto): {{ calculateTotalBrutto() | toCurrency }}</strong></span>
                                    </div>
                                </div>


                            </div>
                        </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
                    <div class="col-md-12">
                <div class="card card-primary card-outline">
                    <div class="card-header">
                        <h3 class="card-title">Positionen</h3>

                        <div class="card-tools">
                            <!-- <button class="mr-1 btn btn-primary" style="display: inline-block" @click="addDepositReturn"><i class="fas fa-plus"></i> Pfandrückgabe</button> -->
                            <button class="mr-1 btn btn-primary" style="display: inline-block" @click="addFreeArticle"><i class="fas fa-plus"></i> Freie Position</button>
                            <button class="mr-1 btn btn-secondary" @click="addDeposit"><i class="fas fa-plus"></i> Pfandrückgabe</button>
                            <button class="btn btn-success" @click="showArticleModal"><i class="fas fa-plus"></i> Artikel</button>
                        </div>
                    </div>

                    <div class="card-body">
                        <table class="table table-striped">
                            <thead>
                                <th style="width:10%;">Artikelnummer</th>
                                <th style="width: 50%;">Name</th>
                                <th style="width: 6%;">Rabattfähig</th>
                                <th style="width: 6%;">Anzahl</th>
                                <th style="width: 10%;" v-if="params.tax==false">Preis (Netto)</th>
                                <th style="width: 10%;" v-if="params.tax==true">Preis</th>
                                <th v-if="params.tax==false">Total (Netto)</th>
                                <th v-if="params.tax==true">Total</th>
                                <th>Steuersatz</th>
                                <th>Aktion</th>
                            </thead>

                            <tbody>
                                <tr v-for="(article, index) in params.articles" :key="article.id">
                                    <td>
                                        <input v-if="article.type == 'article_free'" type="text" v-model="params.articles[index].articleordernumber" class="form-control form-control-sm" />
                                        <span v-else>{{ article.articleordernumber }}</span>
                                    </td>
                                    <td>
                                        <input v-if="article.type == 'article_free' || article.type == 'deposit_sale'" type="text" v-model="params.articles[index].name" class="form-control form-control-sm" />
                                        <span v-else>{{ article.name }}
                                            <br>
                                            <span v-if="article.deposit > 0"><small>Pfandartikel</small></span>
                                        </span>
                                    </td>
                                    <td>
                                        <!-- <div class="mr-1 form-group" style="display: inline-block;"> -->
                                            <div class="custom-control custom-switch" :class="article.type != 'deposit_sale' && article.type != 'deposit_return' && article.type != 'article_flt' && article.type != 'article_tel' && article.type != 'article_email' && article.type != 'prio_ausserhalb' ? 'custom-switch-off-danger custom-switch-on-success' : ''">
                                                <input type="checkbox" class="custom-control-input" :id="'customSwitchDiscountable' + index" v-model="params.articles[index].discountable" :disabled="article.type == 'deposit_sale' || article.type == 'deposit_return' || article.type == 'article_flt' || article.type == 'article_tel' || article.type == 'article_email' || article.type == 'prio_ausserhalb'">
                                                <label class="custom-control-label" :for="'customSwitchDiscountable' + index"></label>
                                            </div>
                                        <!-- </div> -->
                                    </td>
                                    <td><input type="number" min="0" step="1" class="form-control form-control-sm" v-model="params.articles[index].quantity" oninput="validity.valid||(value='');" /></td>
                                    <td>
                                        <input v-if="params.tax==false" type="number" class="form-control form-control-sm" v-model="params.articles[index].price_netto" min="0" step="0.01" oninput="validity.valid||(value='');" />
                                        <input v-if="params.tax==true" type="number" class="form-control form-control-sm" v-model="params.articles[index].price" min="0" step="0.01" oninput="validity.valid||(value='');" />
                                    </td>
                                    <td>
                                        <template v-if="params.tax==false">{{ article.price_netto * article.quantity  | toCurrency }}</template>
                                        <template v-if="params.tax==true">{{ article.price * article.quantity  | toCurrency }}</template>
                                    </td>
                                    <td>
                                        <select v-if="article.type == 'article_free' || article.type=='deposit_sale'" v-model="params.articles[index].tax_rate" class="form-control form-control-sm" >
                                            <option v-for="tax in taxes" :key="tax.id" :value="tax.tax">{{tax.description}}</option>
                                        </select>
                                        <span v-else>{{ article.tax_rate }}</span>
                                        
                                    </td>
                                    <td>
                                        <button class="btn btn-danger" @click="removeItem(index)"><i class="fas fa-trash"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>              
                    </div>  

                    <div class="card-footer">
                        <div class="row">
                            
                            <div class="offset-md-10 col-md-2 text-right">
                                <button type="button" class="btn btn-danger" @click="saveCredit" :disabled="this.params.articles.length <= 0 || !notEmptyObject(this.params.customer)"><i class="fas fa-save"></i> Gutschrift erstellen</button>
                            </div>
                        </div>
                    </div>                    
                </div>
                    </div>
            </div>

            <b-modal id="addressModal" :title="modalTitle" ok-only ok-variant="danger" ok-title="Speichern" size="xl" @ok="createAddress">
        <form ref="valueForm" @submit.stop.prevent="handleSubmit">
            <div class="row">

                <div class="col-md-6">

                    <div class="form-group row">
                        <label for="company" class="control-label col-sm-2 col-form-label col-form-label-sm">Firma</label>
                        <div class="col-sm-9">
                            <input type="text" tabindex="1" class="form-control form-control-sm" name="company" id="company" :class="{'is-invalid': form.errors.has('company')}" v-model="form.company">
                            <has-error :form="form" field="company"></has-error>
                        </div>
                    </div>
                    
                    <div class="form-group row">
                        <label for="first_name" class="control-label col-sm-2 col-form-label col-form-label-sm">Vorname*</label>
                        <div class="col-sm-9">
                            <input type="text" tabindex="3" class="form-control form-control-sm" name="first_name" id="first_name" :class="{'is-invalid': form.errors.has('first_name')}" v-model="form.first_name">
                            <has-error :form="form" field="first_name"></has-error>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label for="street" class="control-label col-sm-2 col-form-label col-form-label-sm">Straße*</label>
                        <div class="col-sm-9">
                            <input type="text" tabindex="5" autocomplete="address-line1" class="form-control form-control-sm" name="street" id="street" :class="{'is-invalid': form.errors.has('street')}" v-model="form.street">
                            <has-error :form="form" field="street"></has-error>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label for="zipcode" class="control-label col-sm-2 col-form-label col-form-label-sm">PLZ*</label>
                        <div class="col-sm-9">
                            <input type="text" tabindex="7" autocomplete="postal-code" class="form-control form-control-sm" name="zipcode" id="zipcode" :class="{'is-invalid': form.errors.has('zipcode')}" v-model="form.zipcode">
                            <has-error :form="form" field="zipcode"></has-error>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label for="phone" class="control-label col-sm-2 col-form-label col-form-label-sm">Telefon</label>
                        <div class="col-sm-9">
                            <input type="text" tabindex="9" class="form-control form-control-sm" name="phone" id="phone" :class="{'is-invalid': form.errors.has('phone')}" v-model="form.phone">
                            <has-error :form="form" field="phone"></has-error>
                        </div>
                    </div>

                </div>

                <div class="col-md-6">

                    <div class="form-group row">
                        <label for="department" class="control-label col-sm-2 offset-sm-1 col-form-label col-form-label-sm">Abteilung</label>
                        <div class="col-sm-9">
                            <input type="text" tabindex="2" name="department" id="department" class="form-control form-control-sm" v-model="form.department" :class="{'is-invalid': form.errors.has('department')}">
                            <has-error :form="form" field="department"></has-error>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label for="last_name" class="control-label col-sm-2 offset-sm-1 col-form-label col-form-label-sm">Nachname*</label>
                        <div class="col-sm-9">
                            <input type="text" tabindex="4" name="last_name" id="last_name" class="form-control form-control-sm" v-model="form.last_name" :class="{'is-invalid': form.errors.has('last_name')}">
                            <has-error :form="form" field="last_name"></has-error>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label for="house_number" class="control-label col-sm-2 offset-sm-1 col-form-label col-form-label-sm">Hausnummer*</label>
                        <div class="col-sm-9">
                            <input type="text" tabindex="6" name="house_number" id="house_number" class="form-control form-control-sm" v-model="form.house_number" :class="{'is-invalid': form.errors.has('house_number')}">
                            <has-error :form="form" field="house_number"></has-error>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label for="city" class="control-label col-sm-2 offset-sm-1 col-form-label col-form-label-sm">Stadt*</label>
                        <div class="col-sm-9">
                            <input type="text" tabindex="8" autocomplete="address-line2" name="city" id="city" class="form-control form-control-sm" v-model="form.city" :class="{'is-invalid': form.errors.has('city')}">
                            <has-error :form="form" field="city"></has-error>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label for="ustid" class="control-label col-sm-2 offset-sm-1 col-form-label col-form-label-sm">Ust.-ID</label>
                        <div class="col-sm-9">
                            <input type="text" tabindex="10" name="ustid" id="ustid" class="form-control form-control-sm" v-model="form.ustid" :class="{'is-invalid': form.errors.has('ustid')}">
                            <has-error :form="form" field="ustid"></has-error>
                        </div>
                    </div>


                </div>
            </div>

            
        </form>
    </b-modal>


            <ArticleModal />
            <CustomerModal />
  </b-container>
</template>

<script>
import ArticleModal from './components/ArticleModal';
import CustomerModal from './components/CustomerModal';
export default {
    name: 'Credits',
    title: 'Gutschriften',

    components: {
        ArticleModal,CustomerModal
    },

    watch: {
        'params.customer': function() {
            this.params.shipping_id = this.params.customer.default_shipping_address_id;
            this.params.billing_id = this.params.customer.default_billing_address_id;

            //Prüfe ob Kunde einen Kundenrabatt oder Gruppenrabatt hat
            if(this.params.customer.discount > 0)
            {
                //Es gibt auf jeden Fall einen Kunden Rabatt
               
                this.credit.discount = this.params.customer.discount;
            }
            else{
                //Es gibt keinen Kundenrabatt. Prüfe ob es einen Kundengruppenrabatt gibt
                if(this.params.customer.group.discount > 0)
                {
                    //Kundengruppenrabatt
                    this.credit.discount = this.params.customer.group.discount;
                }
                else{
                    this.credit.discount = 0;
                }
            }

            if(this.params.customer.group.tax == 1){
                this.params.tax = true;
            }
            else{
                this.params.tax = false;
            }            
        },

        'params.tax': {
            handler(newValue){
                if(newValue == true){
                    //Wir benutzen jetzt Brutto
                    this.changeToBrutto();
                }

                if(newValue == false){
                    //Wir benutzen jetzt Netto
                    this.changeToNetto();
                }
            }
        }
    },

    data(){
        return {
            companies: [],
            taxes: [],
            credit_total: 0,
            credit_total_netto: 0,
            form: new window.Form({
                id: "",
                customer_id: "",
                first_name: "",
                last_name: "",
                street: "",
                house_number: "",
                zipcode: "",
                city: "",
                company: "",
                department: "",
                phone: "",
                ustid: "",
            }),
            modalTitle: '',
            credit: {
                total_brutto: 0,
                total_netto: 0,
                total_pledge: 0,
                total_discount: 0,
                zwischensumme: 0,
                deposit: [],
                tax: [],
                comment: "",
                discount: 0,
            },
            final_credit: {
                invoice_amount: '',
                invoice_amount_net: '',
                company: '',
                department: '',
                first_name: '',
                last_name: '',
                street: '',
                house_number: '',
                zipcode: '',
                city:'',
                ustid: '',
                comment: '',
                items:[]
            },

            params: {
                tax: true,
                company_id: 1,
                articles: [],
                comment: '',
                customer: '',
                useBillingAddress: "true",
                billing_id: '',
                shipping_id: '',
                shipping_address: {},
                useDiscount: true,
                isDistributor: false,
            },
        }
    },

    methods:{
        changeToBrutto(){
            this.params.articles.forEach(function(item) {
                
                if(item.type == 'article' || item.type == 'bundle'){
                    if(item.price_netto != item.price_netto_orig){
                        item.price = parseFloat(item.price_netto / 100 * (100 + parseFloat(item.tax_rate))).toFixed(2);
                    }
                    else{
                        item.price = item.price_orig;
                    }
                    
                }

                if(item.type == 'article_free'){
                    item.price = parseFloat(item.price_netto / 100 * (100 + parseFloat(item.tax_rate))).toFixed(2);
                }

                if(item.type == 'article_flt' || item.type == 'article_tel' || item.type == 'article_email' || item.type == 'prio_ausserhalb' || item.type == 'deposit_sale' || item.type == 'deposit_return'){
                    item.price = item.price_netto;
                }
            });
        },

        changeToNetto(){
            this.params.articles.forEach(function(item) {
                
                if(item.type == 'article' || item.type == 'bundle'){
                    if(item.price != item.price_orig){
                        item.price_netto = parseFloat(item.price / (100 + parseFloat(item.tax_rate)) * 100).toFixed(2);
                    }
                    else{
                        item.price_netto = item.price_netto_orig;
                    }
                }

                if(item.type == 'article_free'){
                    item.price_netto = parseFloat(item.price / (100 + parseFloat(item.tax_rate)) * 100).toFixed(2);
                }

                if(item.type == 'article_flt' || item.type == 'article_tel' || item.type == 'article_email' || item.type == 'prio_ausserhalb' || item.type == 'deposit_sale' || item.type == 'deposit_return'){
                    item.price_netto = item.price;
                }
            });
        },

        calculateSubTotalNetto(){
            var sum = 0;
            this.params.articles.forEach(function(item) {
                

                if(item.type == 'article' || item.type == 'article_free' || item.type == 'bundle'){
                    sum += parseFloat(item.price_netto).toFixed(2) * item.quantity;
                }

                if(item.type == 'article_flt' || item.type == 'article_tel' || item.type == 'article_email' || item.type == 'prio_ausserhalb'){
                    sum += parseFloat(item.price_netto).toFixed(2) * item.quantity;
                }

                if(item.type == 'deposit_sale')
                {
                    sum += parseFloat(item.price_netto).toFixed(2) * item.quantity;
                }
            });
            return sum;
        },

        calculateSubTotalBrutto(){
            var sum = 0;
            this.params.articles.forEach(function(item) {
                

                if(item.type == 'article' || item.type == 'article_free' || item.type == 'bundle'){
                    sum += parseFloat(item.price).toFixed(2) * item.quantity;
                }

                if(item.type == 'article_flt' || item.type == 'article_tel' || item.type == 'article_email' || item.type == 'prio_ausserhalb'){
                    sum += parseFloat(item.price).toFixed(2) * item.quantity;
                }

                if(item.type == 'deposit_sale')
                {
                    sum += parseFloat(item.price).toFixed(2) * item.quantity;
                }
            });
            return sum;
        },

        calculateDepositReturnNetto(){
            var sum = 0;
            this.params.articles.forEach(function(item) {
                if(item.type == 'deposit_return'){
                    sum += parseFloat(item.price_netto).toFixed(2) * item.quantity;
                }
            });
            return sum;
        },

        calculateDepositReturnNettoByTax(tax){
            var sum = 0;
            this.params.articles.forEach(function(item) {
                if(item.type == 'deposit_return' && item.tax_rate == tax){
                    sum += parseFloat(item.price_netto).toFixed(2) * item.quantity;
                }
            });
            return sum;
        },

        calculateDepositReturnBrutto(){
            var sum = 0;
            this.params.articles.forEach(function(item) {
                if(item.type == 'deposit_return'){
                    sum += parseFloat(item.price).toFixed(2) * item.quantity;
                }
            });
            return sum;
        },

        calculateDepositReturnBruttoByTax(tax){
            var sum = 0;
            this.params.articles.forEach(function(item) {
                if(item.type == 'deposit_return' && item.tax_rate == tax){
                    sum += parseFloat(item.price).toFixed(2) * item.quantity;
                }
            });
            return sum;
        },

        calculateDeposit(){
            var sum = 0;
            this.params.articles.forEach(function(item) {
                if(item.type == 'article' || item.type == 'article_free' || item.type == 'bundle'){
                    if(item.deposit > 0)
                    sum += parseFloat(item.deposit).toFixed(2) * item.quantity;
                }
            });
            return sum;
        },

        // calculateFragile(){
        //     var sum = 0;
        //     const dispatch = this.dispatches.find(item => item.id == this.params.dispatch_id);
        //     var surcharge = 0;
        //     if(dispatch != undefined){
        //         surcharge = dispatch.surcharge;
        //     }
        //     const company = this.companies.find(item => item.id == this.params.company_id);
        //     if(surcharge == 1 && this.params.useFragile == true){
        //         this.params.articles.forEach(function(item) {
        //             if(item.type == 'article' || item.type == 'bundle'){
        //                 if(item.fragile == 1)
        //                 sum += parseFloat(company.fragileAmount) * item.quantity;
        //             }
        //         });
        //     }
        //     return sum;
        // },

        getFragileByTax(){
            var fragile = [];
            // const dispatch = this.dispatches.find(item => item.id == this.params.dispatch_id);
            var surcharge = 0;
            // if(dispatch != undefined){
            //     surcharge = dispatch.surcharge;
            // }
            if(surcharge == 1 && this.params.useFragile == true){
                this.params.articles.forEach(function(item) {
                    if(item.type == 'article' || item.type == 'bundle'){
                        if(item.fragile == 1){
                            var tax = fragile.find(i => i.type == item.tax_rate);
                            if(tax == undefined)
                            {
                                //Steuertyp wurde noch nicht gefunden. Erstelle diesen Pfandtyp
                                var entry = {
                                    type: item.tax_rate,
                                    amount: parseInt(item.quantity),
                                };

                                fragile.push(entry);
                            }
                            else
                            {
                                //Der Pfandtyp wurde bereits gefunden. Addiere den Pfand dazu.
                                fragile.amount = parseInt(fragile.amount) + parseInt(item.quantity);
                            }
                        }
                    }
                });
            }
            return fragile;
        },

        getCargoByTax(){
            var cargo = [];
            // const dispatch = this.dispatches.find(item => item.id == this.params.dispatch_id);
            var surcharge = 0;
            // if(dispatch != undefined){
            //     surcharge = dispatch.surcharge;
            // }
            if(surcharge == 1 && this.params.useCargo == true){
                this.params.articles.forEach(function(item) {
                    if(item.type == 'article' || item.type == 'bundle'){
                        if(item.heavy == 1){
                            var tax = cargo.find(i => i.type == item.tax_rate);
                            if(tax == undefined)
                            {
                                //Steuertyp wurde noch nicht gefunden. Erstelle diesen Pfandtyp
                                var entry = {
                                    type: item.tax_rate,
                                    amount: parseInt(item.quantity),
                                };

                                cargo.push(entry);
                            }
                            else
                            {
                                //Der Pfandtyp wurde bereits gefunden. Addiere den Pfand dazu.
                                cargo.amount = parseInt(cargo.amount) + parseInt(item.quantity);
                            }
                        }
                    }
                });
            }
            return cargo;
        },

        getDepositByTax(){
            var deposits = [];
            // const dispatch = this.dispatches.find(item => item.id == this.params.dispatch_id);
            var surcharge = 0;
            // if(dispatch != undefined){
            //     surcharge = dispatch.surcharge;
            // }
            if(surcharge == 1 && this.params.useCargo == true){
                this.params.articles.forEach(function(item) {
                    if(item.type == 'article' || item.type == 'bundle'){
                        if(item.deposit > 0){
                            //Artikel hat Pfand
                            var string = item.deposit_type + '_' + item.tax_rate;

                            var deposit = deposits.find(i => i.type == string);
                            if(deposit == undefined)
                            {
                                //Pfandtyp wurde noch nicht gefunden. Erstelle diesen Pfandtyp
                                var entry = {
                                    type: string,
                                    amount: item.deposit * item.quantity,
                                    tax: item.tax_rate,
                                };

                                deposits.push(entry);
                            }
                            else
                            {
                                //Der Pfandtyp wurde bereits gefunden. Addiere den Pfand dazu.
                                deposit.amount += (item.deposit * item.quantity);
                            }
                        }
                    }
                });
            }
            return deposits;
        },
        
        // calculateCargo(){
        //     var sum = 0;
        //     const dispatch = this.dispatches.find(item => item.id == this.params.dispatch_id);
        //     var surcharge = 0;
        //     if(dispatch != undefined){
        //         surcharge = dispatch.surcharge;
        //     }
        //     const company = this.companies.find(item => item.id == this.params.company_id);
        //     if(surcharge == 1 && this.params.useCargo == true){
        //         this.params.articles.forEach(function(item) {
        //             if(item.type == 'article' || item.type == 'bundle'){
        //                 if(item.heavy == 1)
        //                 sum += parseFloat(company.cargoAmount) * item.quantity;
        //             }
        //         });
        //     }
        //     return sum;
        // },

        needCool()
        {
            var cool = false;

            this.params.articles.forEach(function(item) {
                if(item.cool == 1){
                    cool = true;
                }
            });

            return cool;
        },


        getShippingWithoutTax(){
            if(this.params.tax == true){
                //Es ist Privatkunde
                return Number(parseFloat(parseFloat(this.calculateShippingBrutto()) / (100 + parseFloat(this.getTaxForShipping())) * 100).toFixed(2));
            }
            else{
                //Firmenkunde
                return Number(parseFloat(this.calculateShippingNetto().toFixed(2)));
            }
        },

        getShippingWithTax(){
            if(this.params.tax == true){
                //Es ist Privatkunde
                return Number(parseFloat(this.calculateShippingBrutto().toFixed(2)));
            }
            else{
                //Firmenkunde
                return Number(parseFloat(parseFloat(this.calculateShippingNetto()) / 100 * (parseFloat(this.getTaxForShipping()) + 100)).toFixed(2));
            }
        },

        getTaxForShipping(){
            // const dispatch = this.dispatches.find(item => item.id == this.params.dispatch_id);
            var surcharge = 0;
            // if(dispatch != undefined){
            //     surcharge = dispatch.surcharge;
            // }
            const company = this.companies.find(item => item.id == this.params.company_id);
            var groupedByTax = [];


            this.params.articles.forEach(function(item) {
                var sum = 0;
                if(item.type == 'deposit_return'){
                    sum -= Number(parseFloat(item.price).toFixed(2)) * item.quantity;
                }
                else{
                    sum += Number(parseFloat(item.price).toFixed(2)) * item.quantity;
                }
                
                if(item.fragile == 1 && this.params.useFragile == true && surcharge == 1){
                    sum += Number(parseFloat(company.fragileAmount).toFixed(2)) * item.quantity;
                }
                if(item.heavy == 1 && this.params.useCargo == true && surcharge == 1){
                    sum += Number(parseFloat(company.cargoAmount).toFixed(2)) * item.quantity;
                }
                if(item.deposit > 0){
                    sum += Number(parseFloat(item.deposit).toFixed(2)) * item.quantity;
                }
                var tax = groupedByTax.find(i => i.type == item.tax_rate);
                if(tax == undefined)
                {
                    //Steuertyp wurde noch nicht gefunden. Erstelle diesen Pfandtyp
                    var entry = {
                        type: item.tax_rate,
                        amount: Number(parseFloat(sum).toFixed(2)),
                    };

                    groupedByTax.push(entry);
                }
                else
                {
                    //Der Pfandtyp wurde bereits gefunden. Addiere den Pfand dazu.
                    tax.amount = Number(parseFloat(tax.amount + Number(parseFloat(sum).toFixed(2))).toFixed(2));
                }
            }, this);

            var taxes = [];
            groupedByTax.forEach((item) => {
                var tax = {};
                if(item.amount > 0)
                {
                    const percentOfSubtotal = Number(parseFloat(100 / (this.calculateSubTotalBrutto() + this.calculateDeposit() + this.calculateDepositReturnBruttoByTax(item.type)) * parseFloat(item.amount)).toFixed(2));
                    const actualAmount = Number(parseFloat(((this.calculateSubTotalBrutto() - this.calculateDiscountBrutto() + this.calculateDeposit() + this.calculateDepositReturnBruttoByTax(item.type)) / 100) * percentOfSubtotal).toFixed(2));
                    
                    tax = {
                        'tax':  item.type,
                        'regularAmount': item.amount,
                        'regularTax': Number(parseFloat(item.amount / (100 + parseFloat(item.type)) * parseFloat(item.type).toFixed(2)).toFixed(2)),
                        'percentOfSubtotal': percentOfSubtotal,
                        'actualAmount': actualAmount,
                        'actualTax': Number(parseFloat(actualAmount / (100 + parseFloat(item.type)) * parseFloat(item.type).toFixed(2)).toFixed(2)),
                    };
                }
                else{
                    tax = {
                        'tax':  item.type,
                        'regularAmount': item.amount,
                        'regularTax': Number(parseFloat(item.amount / (100 + parseFloat(item.type)) * parseFloat(item.type).toFixed(2)).toFixed(2)),
                        'percentOfSubtotal': 0,
                        'actualAmount': item.amount,
                        'actualTax': Number(parseFloat(item.amount / (100 + parseFloat(item.type)) * parseFloat(item.type).toFixed(2)).toFixed(2)),
                    };
                }
                taxes.push(tax)
            },this);
            
            var shippingTax = '19.00';
            if(this.ShippingTaxMode == '19')
            {
                shippingTax = '19.00';
            }

            else if(this.ShippingTaxMode == '7')
            {
                shippingTax = '7.00';
            }

            else if(this.ShippingTaxMode == 'calculate')
            {
                shippingTax = '19.00';
                if(taxes.length > 0){
                    const obj = taxes.reduce((prev, current) => (prev.actualAmount > current.actualAmount) ? prev : current);
                    shippingTax = obj.tax;
                }
            }

            if(this.params.customer != null && this.params.customer != '' && this.params.customer.inter_company == 1){
                shippingTax = '0.00';
            }

            return Number(parseFloat(shippingTax).toFixed(2));
        },

        calculateShippingNetto(){
            if(this.calculateSubTotalNetto() < 59)
            {
                //Es fallsen also schonmal Versandkosten an
                //Prüfe nun ob die Versandkosten per Hand verboten wurden
                if(this.params.shippingFree == false)
                {
                    return parseFloat(this.params.shippingCost);
                }
                else{
                    return parseFloat(0);
                }
            }
            else{
                return parseFloat(0);
            }
        },

        calculateShippingBrutto(){
            if(this.calculateSubTotalBrutto() < 59)
            {
                //Es fallsen also schonmal Versandkosten an
                //Prüfe nun ob die Versandkosten per Hand verboten wurden
                if(this.params.shippingFree == false)
                {
                    return parseFloat(this.params.shippingCost);
                }
                else{
                    return parseFloat(0);
                }
            }
            else{
                return parseFloat(0);
            }
        },

        calculateDiscountNetto(){
            var sum = 0;
            if(this.params.useDiscount == true && parseFloat(this.credit.discount) > 0){
                sum = parseFloat(this.getDiscountableTotalNetto() / 100 * parseFloat(this.credit.discount)).toFixed(2);
            }
            return parseFloat(sum);
        },

        calculateDiscountBrutto(){
            var sum = 0;
            if(this.params.useDiscount == true && this.credit.discount > 0){
                sum = parseFloat(this.getDiscountableTotalBrutto() / 100 * this.credit.discount).toFixed(2);
            }
            return parseFloat(sum);
        },

        calculateDiscount(){
            if(this.params.tax == true){
                return this.calculateDiscountBrutto();
            }
            else{
                return this.calculateDiscountNetto();
            }
        },

        getDiscountableTotalNetto(){
            var sum = 0;
            this.params.articles.forEach(function(item) {
                if(item.discountable == true){
                    sum += parseFloat(item.price_netto).toFixed(2) * item.quantity;
                }
            });
            return parseFloat(sum).toFixed(2);
        },

        getDiscountableTotalBrutto(){
            var sum = 0;
            this.params.articles.forEach(function(item) {
                if(item.discountable == true){
                    sum += parseFloat(item.price).toFixed(2) * item.quantity;
                }
            });
            return parseFloat(sum).toFixed(2);
        },

        calculateTotalNetto(){
            var sum = 0;

            if(this.params.tax == false){
                sum += this.calculateSubTotalNetto();
                sum -= this.calculateDiscountNetto();
                sum += this.calculateDeposit();
                // sum += this.calculateCargo();
                // sum += this.calculateFragile();
                sum += this.calculateShippingNetto();
                // sum += this.calculateCooling();
                sum += this.calculateDepositReturnNetto();
            }

            if(this.params.tax == true){
                //Privatkunde
                sum = this.calculateTotalBrutto();
                this.calculateTaxesBrutto().forEach(item => {
                    sum -= item.actualTax;
                });
            }

            return parseFloat(sum);

        },

        calculateTotalBrutto(){
            var sum = 0; 

            if(this.params.tax == false){
                //netto
                sum = this.calculateTotalNetto();
                // console.log('Netto:' + sum);
                this.calculateTaxesNetto().forEach(item => {
                    sum += item.actualTax;
                    // console.log('Tax:' + item.actualTax);
                });
            }

            if(this.params.tax == true){
                //netto
                sum += this.calculateSubTotalBrutto();
                sum -= this.calculateDiscountBrutto();
                sum += this.calculateDeposit();
                // sum += this.calculateCargo();
                // sum += this.calculateFragile();
                sum += this.calculateShippingBrutto();
                // sum += this.calculateCooling();
                sum += this.calculateDepositReturnBrutto();
            }

            return Number(parseFloat(sum).toFixed(2));
        },

        calculateTaxesNetto(){
            // const dispatch = this.dispatches.find(item => item.id == this.params.dispatch_id);
            var surcharge = 0;
            // if(dispatch != undefined){
            //     surcharge = dispatch.surcharge;
            // }
            const company = this.companies.find(item => item.id == this.params.company_id);
            var groupedByTax = [];


            this.params.articles.forEach(function(item) {
                var sum = 0;
                if(item.type == 'deposit_return'){
                    sum += Number(parseFloat(item.price_netto).toFixed(2)) * item.quantity;
                }
                else{
                    sum += Number(parseFloat(item.price_netto).toFixed(2)) * item.quantity;
                }
                
                if(item.fragile == 1 && this.params.useFragile == true && surcharge == 1){
                    sum += Number(parseFloat(company.fragileAmount).toFixed(2)) * item.quantity;
                }
                if(item.heavy == 1 && this.params.useCargo == true && surcharge == 1){
                    sum += Number(parseFloat(company.cargoAmount).toFixed(2)) * item.quantity;
                }
                if(item.deposit > 0){
                    sum += Number(parseFloat(item.deposit).toFixed(2)) * item.quantity;
                }
                var tax = groupedByTax.find(i => i.type == item.tax_rate);
                if(this.params.customer != '' && this.params.customer != null){
                    //Es gibt einen Kunden
                    if(this.params.customer.inter_company == 1){
                        tax = groupedByTax.find(i => i.type == '0.00');
                    }
                }
                
                if(tax == undefined)
                {
                    //Pfandtyp wurde noch nicht gefunden. Erstelle diesen Pfandtyp
                    var entry = {
                        type: item.tax_rate,
                        amount: Number(parseFloat(sum).toFixed(2)),
                    };
                    if(this.params.customer != '' && this.params.customer != null){
                        //Es gibt einen Kunden
                        if(this.params.customer.inter_company == 1){
                            entry = {
                                type: '0.00',
                                amount: Number(parseFloat(sum).toFixed(2)),
                            };
                        }
                    }

                    groupedByTax.push(entry);
                }
                else
                {
                    //Der Pfandtyp wurde bereits gefunden. Addiere den Pfand dazu.
                    tax.amount = Number(parseFloat(tax.amount + Number(parseFloat(sum).toFixed(2))).toFixed(2));
                }
            }, this);

            var taxes = [];
            groupedByTax.forEach((item) => {
                var tax = {};

                if(item.amount > 0)
                {
                    const percentOfSubtotal = Number(parseFloat(100 / (this.calculateSubTotalNetto() + this.calculateDeposit() + this.calculateDepositReturnNettoByTax(item.type)) * parseFloat(item.amount)).toFixed(2));
                    const actualAmount = Number(parseFloat(((this.calculateSubTotalNetto() - this.calculateDiscountNetto() + this.calculateDeposit() + this.calculateDepositReturnNettoByTax(item.type)) / 100) * percentOfSubtotal).toFixed(2));
                    
                    tax = {
                        'tax':  item.type,
                        'regularAmount': item.amount,
                        'regularTax': Number(parseFloat(item.amount / 100 * parseFloat(item.type).toFixed(2)).toFixed(2)),
                        'percentOfSubtotal': percentOfSubtotal,
                        'actualAmount': actualAmount,
                        'actualTax': Number(parseFloat(actualAmount / 100 * parseFloat(item.type).toFixed(2)).toFixed(2)),
                    };
                    
                }
                else{
                    tax = {
                        'tax':  item.type,
                        'regularAmount': item.amount,
                        'regularTax': Number(parseFloat(item.amount / 100 * parseFloat(item.type).toFixed(2)).toFixed(2)),
                        'percentOfSubtotal': 0,
                        'actualAmount': item.amount,
                        'actualTax': Number(parseFloat(item.amount / 100 * parseFloat(item.type).toFixed(2)).toFixed(2)),
                    };
                }
                taxes.push(tax)
            },this);
            
            // var shippingTax = '19.00';
            // if(this.ShippingTaxMode == '19')
            // {
            //     shippingTax = '19.00';
            // }

            // else if(this.ShippingTaxMode == '7')
            // {
            //     shippingTax = '7.00';
            // }

            // else if(this.ShippingTaxMode == 'calculate')
            // {
            //     shippingTax = '19.00';
            //     if(taxes.length > 0){
            //         const obj = taxes.reduce((prev, current) => (prev.actualAmount > current.actualAmount) ? prev : current);
            //         shippingTax = obj.tax;
            //     }
            // }

            // if(this.params.customer != null && this.params.customer != '' && this.params.customer.inter_company == 1){
            //     shippingTax = '0.00';
            // }

            //Addiere jetzt noch den Kühlzuschlag
            // Fest 7% Auf Kühlzuschlag
            // if(this.calculateCooling() > 0){
            //     var tax = taxes.findIndex(item => item.tax == '7.00');
            //     if(tax == -1){
            //         //Versand existiert nicht. Muss erzeugt werden
            //         const taxNew = {
            //             'tax':  '7.00',
            //             'regularAmount': Number(parseFloat(this.calculateCooling()).toFixed(2)),
            //             'regularTax': Number(parseFloat((this.calculateCooling()) / 100 * parseFloat('7.00')).toFixed(2)),
            //             'percentOfSubtotal': 0,
            //             'actualAmount': Number(parseFloat(this.calculateCooling()).toFixed(2)),
            //             'actualTax': Number(parseFloat((this.calculateCooling()) / 100 * parseFloat('7.00')).toFixed(2)),
            //         };
            //         taxes.push(taxNew)
            //     }
            //     else{
            //         taxes[tax].regularAmount = Number(parseFloat(taxes[tax].regularAmount + Number(parseFloat(this.calculateCooling()).toFixed(2))).toFixed(2));
            //         taxes[tax].regularTax = Number(parseFloat(taxes[tax].regularTax + Number(parseFloat((this.calculateCooling()) / 100 * parseFloat('7.00')).toFixed(2))).toFixed(2));
            //         taxes[tax].actualAmount = Number(parseFloat(taxes[tax].actualAmount + Number(parseFloat(this.calculateCooling()).toFixed(2))).toFixed(2));
            //         taxes[tax].actualTax = Number(parseFloat(taxes[tax].actualTax + Number(parseFloat((this.calculateCooling()) / 100 * parseFloat('7.00')).toFixed(2))).toFixed(2));
            //     }
            // }


            // Jetzt addiere noch den Versand
            // var tax = taxes.findIndex(item => item.tax == shippingTax);
            // if(tax == -1){
            //     //Versand existiert nicht. Muss erzeugt werden
            //     const taxNew = {
            //         'tax':  shippingTax,
            //         'regularAmount': Number(parseFloat(this.calculateShippingNetto()).toFixed(2)),
            //         'regularTax': Number(parseFloat((this.calculateShippingNetto()) / 100 * parseFloat(shippingTax)).toFixed(2)),
            //         'percentOfSubtotal': 0,
            //         'actualAmount': Number(parseFloat(this.calculateShippingNetto()).toFixed(2)),
            //         'actualTax': Number(parseFloat((this.calculateShippingNetto()) / 100 * parseFloat(shippingTax)).toFixed(2)),
            //     };
            //     taxes.push(taxNew)
            // }
            // else{
            //     taxes[tax].regularAmount = Number(parseFloat(taxes[tax].regularAmount + Number(parseFloat(this.calculateShippingNetto()).toFixed(2))).toFixed(2));
            //     taxes[tax].regularTax = Number(parseFloat(taxes[tax].regularTax + Number(parseFloat((this.calculateShippingNetto()) / 100 * parseFloat(shippingTax)).toFixed(2))).toFixed(2));
            //     taxes[tax].actualAmount = Number(parseFloat(taxes[tax].actualAmount + Number(parseFloat(this.calculateShippingNetto()).toFixed(2))).toFixed(2));
            //     taxes[tax].actualTax = Number(parseFloat(taxes[tax].actualTax + Number(parseFloat((this.calculateShippingNetto()) / 100 * parseFloat(shippingTax)).toFixed(2))).toFixed(2));
            // }
            // console.log(taxes);
            return taxes;
        },


        calculateTaxesBrutto(){
            // const dispatch = this.dispatches.find(item => item.id == this.params.dispatch_id);
            var surcharge = 0;
            // if(dispatch != undefined){
            //     surcharge = dispatch.surcharge;
            // }
            const company = this.companies.find(item => item.id == this.params.company_id);
            var groupedByTax = [];


            this.params.articles.forEach(function(item) {
                var sum = 0;
                if(item.type == 'deposit_return'){
                    sum += Number(parseFloat(item.price).toFixed(2)) * item.quantity;
                }
                else{
                    sum += Number(parseFloat(item.price).toFixed(2)) * item.quantity;
                }
                
                if(item.fragile == 1 && this.params.useFragile == true && surcharge == 1){
                    sum += Number(parseFloat(company.fragileAmount).toFixed(2)) * item.quantity;
                }
                if(item.heavy == 1 && this.params.useCargo == true && surcharge == 1){
                    sum += Number(parseFloat(company.cargoAmount).toFixed(2)) * item.quantity;
                }
                if(item.deposit > 0){
                    sum += Number(parseFloat(item.deposit).toFixed(2)) * item.quantity;
                }
                var tax = groupedByTax.find(i => i.type == item.tax_rate);
                if(tax == undefined)
                {
                    //Steuertyp wurde noch nicht gefunden. Erstelle diesen Pfandtyp
                    var entry = {
                        type: item.tax_rate,
                        amount: Number(parseFloat(sum).toFixed(2)),
                    };

                    groupedByTax.push(entry);
                }
                else
                {
                    //Der Pfandtyp wurde bereits gefunden. Addiere den Pfand dazu.
                    tax.amount = Number(parseFloat(tax.amount + Number(parseFloat(sum).toFixed(2))).toFixed(2));
                }
            }, this);

            var taxes = [];
            groupedByTax.forEach((item) => {
                var tax = {};
                if(item.amount > 0)
                {
                    const percentOfSubtotal = Number(parseFloat(100 / (this.calculateSubTotalBrutto() + this.calculateDeposit() + this.calculateDepositReturnBruttoByTax(item.type)) * parseFloat(item.amount)).toFixed(2));
                    const actualAmount = Number(parseFloat(((this.calculateSubTotalBrutto() - this.calculateDiscountBrutto() + this.calculateDeposit() + this.calculateDepositReturnBruttoByTax(item.type)) / 100) * percentOfSubtotal).toFixed(2));
                    
                    tax = {
                        'tax':  item.type,
                        'regularAmount': item.amount,
                        'regularTax': Number(parseFloat(item.amount / (100 + parseFloat(item.type)) * parseFloat(item.type).toFixed(2)).toFixed(2)),
                        'percentOfSubtotal': percentOfSubtotal,
                        'actualAmount': actualAmount,
                        'actualTax': Number(parseFloat(actualAmount / (100 + parseFloat(item.type)) * parseFloat(item.type).toFixed(2)).toFixed(2)),
                    };
                }
                else{
                    tax = {
                        'tax':  item.type,
                        'regularAmount': item.amount,
                        'regularTax': Number(parseFloat(item.amount / (100 + parseFloat(item.type)) * parseFloat(item.type).toFixed(2)).toFixed(2)),
                        'percentOfSubtotal': 0,
                        'actualAmount': item.amount,
                        'actualTax': Number(parseFloat(item.amount / (100 + parseFloat(item.type)) * parseFloat(item.type).toFixed(2)).toFixed(2)),
                    };
                }
                taxes.push(tax)
            },this);
            
            // var shippingTax = '19.00';
            // if(this.ShippingTaxMode == '19')
            // {
            //     shippingTax = '19.00';
            // }

            // else if(this.ShippingTaxMode == '7')
            // {
            //     shippingTax = '7.00';
            // }

            // else if(this.ShippingTaxMode == 'calculate')
            // {
            //     shippingTax = '19.00';
            //     if(taxes.length > 0){
            //         const obj = taxes.reduce((prev, current) => (prev.actualAmount > current.actualAmount) ? prev : current);
            //         shippingTax = obj.tax;
            //     }
            // }

            // if(this.calculateCooling() > 0){
            //     //Addiere jetzt noch den Kühlzuschlag
            //     // Fest 7% Auf Kühlzuschlag
            //     var tax = taxes.findIndex(item => item.tax == '7.00');
            //     if(tax == -1){
            //         //Versand existiert nicht. Muss erzeugt werden
            //         const taxNew = {
            //             'tax':  '7.00',
            //             'regularAmount': Number(parseFloat(this.calculateCooling()).toFixed(2)),
            //             'regularTax': Number(parseFloat((this.calculateCooling()) / 107 * parseFloat('7.00')).toFixed(2)),
            //             'percentOfSubtotal': 0,
            //             'actualAmount': Number(parseFloat(this.calculateCooling()).toFixed(2)),
            //             'actualTax': Number(parseFloat((this.calculateCooling()) / 107 * parseFloat('7.00')).toFixed(2)),
            //         };
            //         taxes.push(taxNew)
            //     }
            //     else{
            //         taxes[tax].regularAmount = Number(parseFloat(taxes[tax].regularAmount + Number(parseFloat(this.calculateCooling()).toFixed(2))).toFixed(2));
            //         taxes[tax].regularTax = Number(parseFloat(taxes[tax].regularTax + Number(parseFloat((this.calculateCooling()) / 107 * parseFloat('7.00')).toFixed(2))).toFixed(2));
            //         taxes[tax].actualAmount = Number(parseFloat(taxes[tax].actualAmount + Number(parseFloat(this.calculateCooling()).toFixed(2))).toFixed(2));
            //         taxes[tax].actualTax = Number(parseFloat(taxes[tax].actualTax + Number(parseFloat((this.calculateCooling()) / 107 * parseFloat('7.00')).toFixed(2))).toFixed(2));
            //     }
            // }


            // Jetzt addiere noch den Versand
            // var tax = taxes.findIndex(item => item.tax == shippingTax);
            // if(tax == -1){
            //     //Versand existiert nicht. Muss erzeugt werden
            //     const taxNew = {
            //         'tax':  shippingTax,
            //         'regularAmount': Number(parseFloat(this.calculateShippingBrutto()).toFixed(2)),
            //         'regularTax': Number(parseFloat((this.calculateShippingBrutto()) / (100 + parseFloat(shippingTax)) * parseFloat(shippingTax)).toFixed(2)),
            //         'percentOfSubtotal': 0,
            //         'actualAmount': Number(parseFloat(this.calculateShippingBrutto()).toFixed(2)),
            //         'actualTax': Number(parseFloat((this.calculateShippingBrutto()) / (100 + parseFloat(shippingTax)) * parseFloat(shippingTax)).toFixed(2)),
            //     };
            //     taxes.push(taxNew)
            // }
            // else{
            //     taxes[tax].regularAmount = Number(parseFloat(taxes[tax].regularAmount + Number(parseFloat(this.calculateShippingBrutto()).toFixed(2))).toFixed(2));
            //     taxes[tax].regularTax = Number(parseFloat(taxes[tax].regularTax + Number(parseFloat((this.calculateShippingBrutto()) / (100 + parseFloat(shippingTax)) * parseFloat(shippingTax)).toFixed(2))).toFixed(2));
            //     taxes[tax].actualAmount = Number(parseFloat(taxes[tax].actualAmount + Number(parseFloat(this.calculateShippingBrutto()).toFixed(2))).toFixed(2));
            //     taxes[tax].actualTax = Number(parseFloat(taxes[tax].actualTax + Number(parseFloat((this.calculateShippingBrutto()) / (100 + parseFloat(shippingTax)) * parseFloat(shippingTax)).toFixed(2))).toFixed(2));
            // }
            // console.log(taxes);
            return taxes;
        },

        //Berechne nun den Gutschein
        calculateTotalVoucher(){
            var sum = 0;
            if(this.order.vouchers.length >= 1){
                const total = this.calculateTotalBrutto();
                if(this.order.vouchers[0].rest_amount >= total){
                    sum = parseFloat(total).toFixed(2);
                }
                else{
                    sum = parseFloat(this.order.vouchers[0].rest_amount).toFixed(2);
                }
            }
            return parseFloat(sum);
        },

        createAddress(bvModalEvt)
        {
            bvModalEvt.preventDefault()
            this.$Progress.start();
            this.form.customer_id = this.params.customer.id;
            this.form
                .post("/addresses")
                .then(() => {
                    this.$swal({
                        icon: "success",
                        title: "Addresse erfolgreich hinzugefügt",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.$bvModal.hide("addressModal");
                    this.$Progress.finish();
                    this.getCustomer(this.form.customer_id);                    
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        openAddressModal()
        {
            this.modalTitle = "Neue Adresse anlegen";
            this.form.clear();
            this.form.reset();
            this.$bvModal.show("addressModal");
        },

        getCustomer(id){
            this.$Progress.start();
            this.axios
                .get("/customers/" + id)
                .then((response) => {
                    this.params.customer = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        selectCustomer()
        {
            //this.getCustomers();
            this.$bvModal.show('customerModal');
        },

        addFreeArticle(){
            this.params.articles.push({
                'articleordernumber': 'FA',
                'name': 'Freier Artikel',
                'quantity': 1,
                'price': 1,
                'price_netto': 1,
                'tax_rate': '7.00',
                'article_id': 0,
                'article_detail_id': 0,
                'type': 'article_free',
                'deposit': 0,
                'deposit_type': 'Mehrweg-Pfand',
                'discountable': true,
            });
        },

        addDeposit(){
            this.params.articles.push({
                'articleordernumber': 'Pfand',
                'name': 'Pfandrückgabe',
                'quantity': 1,
                'price': 1,
                'price_netto': 1,
                'tax_rate': '19.00',
                'article_id': 0,
                'article_detail_id': 0,
                'type': 'deposit_return',
                'deposit': 0,
                'deposit_type': 'Mehrweg-Pfand',
                'discountable': false,
            });
        },

        showArticleModal(){
            this.$bvModal.show("articleModal");
        },

        saveCredit(){
            const billing = this.params.customer.addresses.find(i => i.id == this.params.billing_id);

            if(this.params.tax == true){
                this.final_credit.netto = false;
            }
            else{
                this.final_credit.netto = true;
            }
            
            this.final_credit.customer_id = this.params.customer.id;
            this.final_credit.company_id = this.params.company_id;
            this.final_credit.invoice_amount = this.calculateTotalBrutto();
            this.final_credit.invoice_amount_net = this.calculateTotalNetto();
            this.final_credit.company = billing.company;
            this.final_credit.department = billing.deparment;
            this.final_credit.first_name = billing.first_name;
            this.final_credit.last_name = billing.last_name;
            this.final_credit.street = billing.street;
            this.final_credit.house_number = billing.house_number;
            this.final_credit.zipcode = billing.zipcode;
            this.final_credit.city = billing.city;
            this.final_credit.ustid = billing.ustid;
            this.final_credit.comment = this.params.comment;
            if(parseFloat(this.calculateDiscount()) > 0){
                this.final_credit.discount = this.calculateDiscount();
            }
            else
            {
                this.final_credit.discount = null;
            }
            this.final_credit.items = [];

            this.params.articles.forEach(function(item){
                var tax = this.taxes.find(taxx => taxx.tax == item.tax_rate);
                var price = 0;
                if(item.type == 'article' || item.type == 'article_free' || item.type == "bundle")
                {
                    //Normaler Artikel = Preis in Brutto und fertig
                    if(this.params.tax == true){
                        price = Number(parseFloat(item.price).toFixed(2));
                    }
                    else{
                        price = Number(parseFloat(item.price_netto / 100 * (parseFloat(item.tax_rate) + 100)).toFixed(2));
                    }
                    
                }

                if(item.type == 'deposit_return')
                {
                    //Prüfe nun ob es ein Firmen oder Privatkunde ist
                    if(this.params.tax == true)
                    {
                        //Kunde ist ein Privatkunde
                        price = Number(parseFloat(item.price).toFixed(2));
                    }

                    else
                    {
                        //Kunde ist Firmenkunde. Pfand ist plus Mwst
                        price = ((parseFloat(item.price_netto) / 100) * (100 + parseFloat(item.tax_rate)));
                    }
                }
               
                this.final_credit.items.push({
                    article_id: item.article_id,
                    article_detail_id: item.article_detail_id,
                    articleordernumber: item.articleordernumber,
                    price: price,
                    price_netto: item.price_netto,
                    quantity: item.quantity,
                    name: item.name,
                    tax_id: tax.id,
                    tax_rate: item.tax_rate,
                    discountable: item.discountable,
                });
                
            },this);

            this.axios
                .post("/credits", this.final_credit)
                .then((response) => {
                    this.$swal({
                        icon: "success",
                        title: "Gutschrift wurde erstellt",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.$router.push({
                        name: 'credits.show',
                        params:{
                            id: response.data.data.id
                        }
                    });
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        removeItem(index)
        {
            this.$delete(this.params.articles, index);
        },

        getTaxes(){
            this.$Progress.start();
            this.axios
                .get("/taxes")
                .then((response) => {
                    this.taxes = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },


        getCompanies(){
            this.$Progress.start();
            this.axios
                .get("/companies")
                .then((response) => {
                    this.companies = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },
    },

    created() {
        this.getCompanies();
        this.getTaxes();
    }
}
</script>

<style>

</style>
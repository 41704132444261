<template>
  <b-modal id="customerModal" title="Kunde wählen" ok-only ok-variant="danger" ok-title="Speichern" size="xl">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-outline card-primary">
              <div class="card-header">
                <h3 class="card-title">Wähle einen Kunden für die Gutschrift</h3>
                <div class="card-tools">
                   
                    <div class="input-group input-group-sm">
                        <input type="text" class="form-control" placeholder="Suchen" @keyup.enter="getCustomers" v-model="search" />
                        <button type="submit" class="btn btn-default" @click.prevent="getCustomers">
                            <i class="fas fa-search"></i>
                        </button>
                    </div>
                                    
                                
                </div>
              </div>
              <div class="card-body">
                <table class="table table-striped">
                                    <thead>
                                        <tr data-widget="expandable-table" aria-expanded="false">
                                            <th>
                                                Kundennummer
                                            </th>
                                            <th>
                                                E-Mail
                                            </th>
                                            <th>
                                                Firma
                                            </th>
                                            <th>
                                                Vorname
                                            </th>
                                            <th>
                                                Nachname
                                            </th>
                                            <th>
                                                PLZ
                                            </th>
                                            <th>
                                                Stadt
                                            </th>
                                            <th>Aktionen</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="customer in customers.data" :key="customer.id">
                                            <td>{{ customer.customernumber}}</td>
                                            <td>{{ customer.email}}</td>
                                            <td>{{ customer.billing_address.company }}</td>
                                            <td>{{ customer.first_name }}</td>
                                            <td>{{ customer.last_name }}</td>
                                            <td>{{ customer.billing_address.zipcode }}</td>
                                            <td>{{ customer.billing_address.city }}</td>
                                            <td>
                                                <b-button size="xs" @click="setCustomer(customer)" variant="primary"><i class="fas fa-user"></i> Auswählen</b-button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
              </div>
              <div class="card-footer">
                        <pagination class="float-left" :data="customers" @pagination-change-page="getCustomers" :limit="3"></pagination>
                        <p v-if="notEmptyObject(customers)" class="float-right mr-2">Anzeige Eintrag {{ customers.meta.from }} - {{ customers.meta.to }} von {{ customers.meta.total }}</p>
                    </div>
            </div>
          </div>
        </div>

        
    </b-modal>
</template>

<script>
export default {
    name: 'CustomerModal',
    title: "Kunden wählen",

    data(){
        return{
            customers: {},
            search: '',
        }
    },

    methods:{
        setCustomer(customer){
            this.$parent.params.customer = customer;
            // this.$parent.params.company = customer.company
            this.$parent.$bvModal.hide('customerModal');
        },

        getCustomers(page = 1){
            this.$Progress.start();
            this.axios
                .get("/customers?company_id=" + this.$parent.params.company_id + '&search=' + this.search + '&accountmode=0&page=' + page)
                .then((response) => {
                    this.customers = response.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },
    },

    created(){
        this.getCustomers();
    }



}
</script>

<style>

</style>